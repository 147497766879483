import { useNavigation } from "@remix-run/react";
import { cn } from "~/lib/utils";

function GlobalLoading() {
  const navigation = useNavigation();
  const active = navigation.state !== "idle";

  return (
    <div
      role="progressbar"
      aria-valuetext={active ? "Loading" : undefined}
      aria-hidden={!active}
      className={cn(
        "pointer-events-none fixed inset-0 z-50 transition-all duration-300 ease-out",
        active ? "opacity-100" : "opacity-0"
      )}
    >
      {/* Backdrop overlay with improved blur */}
      <div className="absolute inset-0 bg-background/40 backdrop-blur-sm" />
      
      {/* Loading container with enhanced glass effect */}
      <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transition-transform duration-300">
        <div className="flex flex-col items-center gap-4 rounded-xl border border-border/50 bg-background/60 px-8 py-6 shadow-xl backdrop-blur-xl">
          {/* Enhanced spinner */}
          <div className="relative h-14 w-14">
            {/* Outer pulsing ring */}
            <div className="absolute inset-0 rounded-full border-[3px] border-primary/20 animate-pulse" />
            {/* Main spinning border */}
            <div className="absolute inset-0 rounded-full border-[3px] border-primary animate-spin" 
                 style={{ 
                   borderRightColor: 'transparent', 
                   borderBottomColor: 'transparent',
                   animationDuration: '0.8s'
                 }} />
            {/* Inner spinning border (opposite direction) */}
            <div className="absolute inset-[4px] rounded-full border-[2px] border-primary/60 animate-spin"
                 style={{ 
                   borderLeftColor: 'transparent', 
                   borderTopColor: 'transparent',
                   animationDuration: '1.2s'
                 }} />
          </div>
          
          {/* Enhanced loading text with smoother animation */}
          <div className="flex items-center gap-1.5">
            <span className="text-sm font-medium text-foreground/90">Loading</span>
            <span className="flex gap-1">
              {[...Array(3)].map((_, i) => (
                <span
                  key={i}
                  className="h-1.5 w-1.5 rounded-full bg-primary animate-bounce"
                  style={{ 
                    animationDelay: `${i * 150}ms`,
                    animationDuration: '1s'
                  }}
                />
              ))}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export { GlobalLoading };
